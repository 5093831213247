<template>
    <div>
        <CRow>
            <CCol sm="6">
                <CCard color="primary" textColor="white">
                    <CCardHeader>Cloudcash</CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol sm="6">
                                <h6 id="traffic" class="card-title mb-0">Sisa Cloudcash Belum Ditarik</h6>
                                <b>{{sisa_cloudcash}}</b>
                            </CCol>
                            <CCol sm="6">
                                <h6 id="traffic" class="card-title mb-0">Total Withdraw Cloudcash</h6>
                                <b>{{total_withdraw_cloudcash}}</b>
                            </CCol>
                        </CRow>
                        <!-- <CChartDoughnut
                            :datasets="defaultDatasets"
                            :labels="['Total Sisa Bitcoin Walet', 'Total Withdraw']"
                        /> -->
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol sm="6">
                <CCard color="success" textColor="white">
                    <CCardHeader>Bitcoin</CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol sm="6">
                                <h6 id="traffic" class="card-title mb-0">Sisa Bitcoin Belum Ditarik</h6>
                                <b>{{sisa_bitcoin}}</b>
                            </CCol>
                            <CCol sm="6">
                                <h6 id="traffic" class="card-title mb-0">Total Withdraw Bitcoin</h6>
                                <b>{{total_withdraw_bitcoin}}</b>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>Daftar Member Yang Belum Tarik Bitcoin</CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol sm="6">
                                <h6>Total Member : {{jumlah_user_belum_tarik_btc}}</h6>
                            </CCol>
                            <CCol sm="12">
                                <CDataTable
                                    hover
                                    striped
                                    :items="items"
                                    :fields="fields"
                                    :items-per-page="10"
                                    :active-page="activePage"
                                    :pagination="{ doubleArrows: false, align: 'center'}"
                                    @page-change="pageChange"
                                />
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import axios from 'axios';
import { CChartDoughnut } from '@coreui/vue-chartjs'

export default {
  name: 'CChartDoughnutExample',
  components: { CChartDoughnut },
  data(){
      return{
        urlApi:this.$store.state.urlApi,
        sisa_cloudcash:0,
        total_withdraw_cloudcash:0,
        sisa_bitcoin:0,
        total_withdraw_bitcoin:0,
        jumlah_user_belum_tarik_btc:0,
        items: [],
        fields: [
            { key: 'member_username', label: 'Username', _classes: 'font-weight-bold' },
            { key: 'member_name', label: 'Member Name', },
            { key: 'member_mobile_no', label: 'Mobile No' },
            { key: 'member_email', label: 'Email' }
        ],
        activePage: 1
      }
  },
  mounted(){
    axios.get(`${this.urlApi}/api/user/getTotalLogWithdraw`).then(response => {
      this.sisa_cloudcash = response.data.total_sisa_cc
      this.total_withdraw_cloudcash = response.data.total_withdraw_cc
      this.sisa_bitcoin = response.data.total_sisa_btc.toFixed(8)
      this.total_withdraw_bitcoin = response.data.total_withdraw_btc
    })

    axios.get(`${this.urlApi}/api/user/get-user-havenot-withdraw`).then(response => {
      this.items = response.data.data
      this.jumlah_user_belum_tarik_btc = response.data.jumlah
    })
  },
  computed: {
    defaultDatasets () {
      return [
        {
          backgroundColor: [
            '#41B883',
            '#E46651'
          ],
          data: [1.778266749999999, 0.12713604]
        }
      ]
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods:{
    rowClicked (item, index) {
      this.$router.push({path: `users/${index + 1}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
  }
}
</script>
